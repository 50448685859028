import {
  ChartFilterElements,
  ChartFilterElementsForProviderSummary
} from '../../interfaces/charts';
import { Pagination } from '../../interfaces/pagination';
import {
  NewOrNrProviderDetailPayload,
  Provider,
  ProviderContact,
  ProviderIpInfoRequest,
  mapProviderToProviderPost
} from '../../interfaces/provider';
import { FilterElement } from '../../lib/FilterElement';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { downloadFile } from '../../lib/utilities';
import { addCommentObject } from '../comment/thunks';
import { getHopsOrProvidersCountriesApiCall } from '../hop/apiCalls';
import {
  addProvider,
  editProvider,
  getAllProvidersStats,
  getAverageDailyTrafficStatsAction,
  getNewProvidersInfoAction,
  getNfProvidersInfoAction,
  getNrProvidersInfoAction,
  getProvider,
  getProviderActionsStatsAction,
  getProviderComments,
  getProviderContacts,
  getProviderIsNonResponsiveAction,
  getProviderNames,
  getProviderStatesAction,
  getProviderStats,
  getProviderStirShakenStatsAction,
  getProviderSummaryHigh,
  getProviderSummaryInternational,
  getProviderSummaryLow,
  getProviderTypes,
  getProviderUpstreamSummary,
  getProviders,
  getProvidersCountriesAction,
  getProvidersIpInfoAction,
  getProvidersList,
  getProvidersNRStats,
  getProvidersORGStats,
  setErrorMessage,
  setLoadingStatus,
  updatePendingProvider
} from './actions';
import {
  addProviderApiCall,
  deleteProviderApiCall,
  editProviderApiCall,
  getAllProvidersStatsApiCall,
  getAverageDailyTrafficStatsApiCall,
  getCSVApiCall,
  getNewProvidersInfoApiCall,
  getNrOrNfProvidersInfoApiCall,
  getProviderActionsStatsApiCall,
  getProviderApiCall,
  getProviderCommentsApiCall,
  getProviderContactsApiCall,
  getProviderIsNonResponsiveApiCall,
  getProviderNamesApiCall,
  getProviderStatsApiCall,
  getProviderStirShakenStatsApiCall,
  getProviderSummaryApiCall,
  getProviderTypesApiCall,
  getProviderUpstreamSummaryApiCall,
  getProvidersApiCall,
  getProvidersIpInfoApiCall,
  getProvidersListApiCall,
  getProvidersNRStatsApiCall,
  getProvidersORGStatsApiCall,
  getProvidersStatesApiCall,
  renotifyProviderApiCall,
  updatePendingProviderApiCall
} from './apiCalls';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getAllProvidersList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const providers = await getProvidersListApiCall();
    if (providers && providers.data) {
      dispatch(getProvidersList(providers.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getProviderList =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const providers = await getProvidersApiCall(params, filterElements);
      if (providers && providers.data) {
        dispatch(getProviders(providers.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getProvidersCountries =
  (filterElements: ChartFilterElements) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const countries = await getHopsOrProvidersCountriesApiCall(filterElements);
      if (countries && countries.data) {
        dispatch(getProvidersCountriesAction(countries.data));
      } else {
        dispatch(getProvidersCountriesAction([]));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getProviderObject = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const provider = await getProviderApiCall(providerId);
    if (provider) {
      dispatch(getProvider(provider));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const addProviderObject =
  (
    provider: Provider,
    comment: string,
    attachments: any[],
    providerContacts: ProviderContact[],
    isReload: boolean = true
  ) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    //providerContacts are unused, but still need the argument
    //since the same signature is used for both add and edit.

    try {
      let response = await addProviderApiCall(mapProviderToProviderPost(provider));

      if (response && response.data) {
        dispatch(addProvider(response.data));

        let newProviderId = response.data.data.providerId;

        if (comment || attachments.length > 0) {
          addCommentObject(
            {
              commentType: 'provider',
              contentText: comment,
              relatedObjectId: newProviderId
            },
            attachments
          ).then(() => {
            dispatch(setStatus(false, ''));

            if (isReload) {
              window.location.href = `providers/provider/${newProviderId}`;
            }
          });
        } else {
          dispatch(setStatus(false, ''));

          if (isReload) {
            window.location.href = `providers/provider/${newProviderId}`;
          }
        }
      }
    } catch (error: any) {
      if (error.response.data.error) {
        dispatch(setStatus(false, error.response.data.error));
      } else {
        dispatch(setStatus(false, error.message));
      }
      logoutOnAuthError(error);
    }
  };

export const editProviderObject =
  (
    provider: Provider,
    comment: string,
    attachments: any[],
    providerContacts: ProviderContact[],
    isReload: boolean = true
  ) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    /*
        let addedContacts = providerContacts.filter((contact) => contact.isAdded);
        addedContacts = addedContacts.map((ac) => {
            const {isAdded, ...contact} = ac;
            return {
                ...contact,
                contactId: 0,
                providerId: provider.providerId,
            };
        });
    */
    //TODO: Handle the addedContacts

    try {
      const response = await editProviderApiCall(
        provider.providerId,
        mapProviderToProviderPost(provider)
      );

      if (response && response.data) {
        dispatch(editProvider(response.data));

        if (comment || attachments.length > 0) {
          addCommentObject(
            {
              commentType: 'provider',
              contentText: comment,
              relatedObjectId: provider.providerId
            },
            attachments
          ).then(() => {
            dispatch(setStatus(false, ''));

            if (isReload) {
              window.location.reload();
            }
          });
        } else {
          if (isReload) {
            window.location.reload();
          }
        }
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const updatePendingProviderObject =
  (
    provider: Provider,
    comment: string,
    attachments: any[],
    providerContacts: ProviderContact[],
    isReload: boolean = true
  ) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await updatePendingProviderApiCall(
        provider.providerId,
        mapProviderToProviderPost(provider)
      );

      if (response && response.data) {
        dispatch(updatePendingProvider(response.data));

        if (comment || attachments.length > 0) {
          addCommentObject(
            {
              commentType: 'provider',
              contentText: comment,
              relatedObjectId: provider.providerId
            },
            attachments
          ).then(() => {
            dispatch(setStatus(false, ''));

            if (isReload) {
              window.location.reload();
            }
          });
        } else {
          if (isReload) {
            window.location.reload();
          }
        }
      }
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getProviderTypeList = () => async (dispatch: any) => {
  try {
    const response = await getProviderTypesApiCall();
    if (response && response.data) {
      dispatch(getProviderTypes(response.data));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getProviderContactList = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await getProviderContactsApiCall(providerId);
    if (response && response.data) {
      dispatch(getProviderContacts(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const renotifyProvider = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await renotifyProviderApiCall(providerId);
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const deleteProvider = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    await deleteProviderApiCall(providerId);
    dispatch(setStatus(false, ''));
    window.location.href = '/Providers';
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getProviderUpstreamSummaryObject =
  (params: Pagination, filterElements: FilterElement, providerId: number) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getProviderUpstreamSummaryApiCall(params, filterElements, providerId);
      if (response && response.data) {
        dispatch(getProviderUpstreamSummary(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getProviderSummaryObject =
  (filterElements: FilterElement, providerId: number) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getProviderSummaryApiCall(filterElements, providerId);
      if (response && response.data) {
        if (
          filterElements.conditionals?.find(
            (element) => element.name === 'isInternational' && element.value === '1'
          )
        )
          dispatch(getProviderSummaryInternational(response.data));
        else if (
          filterElements.conditionals?.find(
            (element) => element.name === 'isLowVolume' && element.value === '1'
          )
        )
          dispatch(getProviderSummaryLow(response.data));
        else dispatch(getProviderSummaryHigh(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getProviderStatsObject =
  (filterElements: ChartFilterElementsForProviderSummary, providerId: number) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getProviderStatsApiCall(filterElements, providerId);
      if (response && response.data) {
        dispatch(getProviderStats(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getAllProviderStatsObject =
  (filterElements: ChartFilterElements) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getAllProvidersStatsApiCall(filterElements);
      if (response && response.data) {
        dispatch(getAllProvidersStats(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getProviderActionStats =
  (filterElements: ChartFilterElementsForProviderSummary, providerId: number) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getProviderActionsStatsApiCall(filterElements, providerId);
      if (response && response.data) {
        dispatch(getProviderActionsStatsAction(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getAverageDailyTrafficStats =
  (filterElements: ChartFilterElementsForProviderSummary, providerId: number) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getAverageDailyTrafficStatsApiCall(filterElements, providerId);
      if (response && response.data) {
        dispatch(getAverageDailyTrafficStatsAction(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getProviderNameList = () => async (dispatch: any) => {
  try {
    const response = await getProviderNamesApiCall();
    if (response && response.data) {
      dispatch(getProviderNames(response.data));
    }
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getProvidersCSV =
  (filterElements: FilterElement, params: Pagination) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const response = await getCSVApiCall(filterElements, params);
      downloadFile(response.data, response.headers.contentfilename);
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getProvidersNRStatsObject = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getProvidersNRStatsApiCall();
    if (response && response.data) {
      dispatch(getProvidersNRStats(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    logoutOnAuthError(error);
    dispatch(setStatus(false, error.message));
  }
};
export const getProvidersORGStatsObject = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getProvidersORGStatsApiCall();
    if (response && response.data) {
      dispatch(getProvidersORGStats(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    logoutOnAuthError(error);
    dispatch(setStatus(false, error.message));
  }
};

export const getProviderIsNonResponsive = (providerId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getProviderIsNonResponsiveApiCall(providerId);
    if (response && response.data) {
      dispatch(getProviderIsNonResponsiveAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    logoutOnAuthError(error);
    dispatch(setStatus(false, error.message));
  }
};

export const getNewProvidersInfo =
  (request: NewOrNrProviderDetailPayload, params: Pagination) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getNewProvidersInfoApiCall(request, params);
      if (response && response.data) {
        dispatch(getNewProvidersInfoAction(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getNrOrProvidersInfo =
  (request: NewOrNrProviderDetailPayload, params: Pagination) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getNrOrNfProvidersInfoApiCall(request, params);
      if (response && response.data) {
        request.isNr
          ? dispatch(getNrProvidersInfoAction(response.data))
          : dispatch(getNfProvidersInfoAction(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getProvidersIpInfo = (request: ProviderIpInfoRequest) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getProvidersIpInfoApiCall(request);
    if (response && response.data) {
      dispatch(getProvidersIpInfoAction(response.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    logoutOnAuthError(error);
    dispatch(setStatus(false, error.message));
  }
};

export const getProviderStates = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const response = await getProvidersStatesApiCall();
    if (response && response.data) dispatch(getProviderStatesAction(response.data));
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getProviderStirShakenStatsObject =
  (filterElements: ChartFilterElementsForProviderSummary, providerId: number) =>
  async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const response = await getProviderStirShakenStatsApiCall(filterElements, providerId);
      if (response && response.data) {
        dispatch(getProviderStirShakenStatsAction(response.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      logoutOnAuthError(error);
      dispatch(setStatus(false, error.message));
    }
  };

export const getProviderCommentsObject =
  (providerId: number, onlyGov?: boolean) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    try {
      const provider = await getProviderCommentsApiCall(providerId, onlyGov);
      if (provider) {
        dispatch(getProviderComments(provider));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };
