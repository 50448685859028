import React, { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Label,
  Row
} from 'reactstrap';
import { visibleIncidentSources } from '../components/AdminDashboard/Incidents/utilities';
import AttestationHighVolumeCampaigns from '../components/Charts/AttestationHighVolumeCampaigns';
import CampaignTracebackVolumeChart from '../components/Charts/CampaignTracebackVolumeChart';
import MapChart from '../components/Charts/MapChart';
import NRProvidersStats from '../components/Charts/NRProvidersStats';
import OriginProvidersActionsChart from '../components/Charts/OriginProvidersActionsChart';
import OriginSignedStatsChart from '../components/Charts/OriginSignedStatsChart';
import TracebacksCampaignLabelChart from '../components/Charts/TracebacksCampaignLabelChart';
import CustomSelect from '../components/CustomSelect';
import DownloadPdfFromView from '../components/DownloadPdfFromView';
import HomePageCustomTables from '../components/HomePageCustomTables';
import HomePageProviderTable from '../components/HomePageProviderTable';
import LabelSelect from '../components/shared/LabelSelect';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { userTypes } from '../enum/userTypes';
import { Option } from '../interfaces/campaign';
import { ChartFilterElements } from '../interfaces/charts';
import { ReportFormat, ReportType } from '../interfaces/report';
import { PersonalInfo } from '../interfaces/user';
import {
  dateOptions,
  getApiFormattedDate,
  getDateFromPeriod,
  getEndDateFromPeriod,
  getStartDateFromPeriod
} from '../lib/utilities';
import { downloadReportApi } from '../redux/report/thunks';
import { stateMappings } from '../redux/stateMappings';
import { ProviderInfoType } from '../lib/chart/data';
import CustomToolTip from '../components/CustomToolTip';

interface IProps {
  user: PersonalInfo;
  downloadReportApi: Function;
}

const sourceOptions = visibleIncidentSources.map((sourceOption) => ({
  value: sourceOption.sourceId,
  label: sourceOption.name
}));

const HomePage: React.FC<IProps> = ({ user, downloadReportApi }) => {
  let [period, setPeriod] = useState<Option>({
    value: 7,
    label: 'All'
  });
  const [campaignLabelName, setCampaignLabelName] = useState('All Campaigns Labels');
  const [filterElements, setFilterElements] = useState<ChartFilterElements>({
    labelId: 0,
    startDate: getApiFormattedDate(getStartDateFromPeriod(period) || ''),
    endDate: getApiFormattedDate(getEndDateFromPeriod(period) || ''),
    sourceId: 0
  });
  const [collapseFilters, setCollapseFilter] = useState(false);

  const printPDF = useRef<HTMLDivElement | null>(null);
  const [source, setSource] = useState(false);
  const [sourceOption, setSourceOption] = useState<Option>();
  const userSourceOption = visibleIncidentSources.find((v) => v.providerId === user.providerId);
  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'selectedPeriod':
        setPeriod(e);
        let dateRange = getDateFromPeriod(e);
        setFilterElements({
          ...filterElements,
          startDate: getApiFormattedDate(dateRange.startDate),
          endDate: getApiFormattedDate(dateRange.endDate)
        });
        break;
      case 'labelId':
        if (!e) {
          setFilterElements({
            ...filterElements,
            labelId: 0
          });
          setCampaignLabelName('All Campaigns Labels');
        } else {
          setFilterElements({
            ...filterElements,
            labelId: e.value
          });
          setCampaignLabelName(e.label);
        }

        break;
      case 'source':
        setSource(!source);
        setFilterElements({
          ...filterElements,
          sourceId: !source ? (userSourceOption ? userSourceOption.sourceId : 0) : 0
        });
        break;
    }
  };
  const handleSourceSelect = (e: any) => {
    setSourceOption(e);
    setFilterElements({
      ...filterElements,
      sourceId: e.value
    });
  };
  const toggleFilters = () => {
    setCollapseFilter(!collapseFilters);
  };

  return (
    <Fragment>
      <Card fluid="sm" className="homePage table-card mt-5">
        <Fragment>
          <div ref={printPDF}>
            {(userTypes.Admin === user.roleType || userTypes.GovUser === user.roleType) && (
              <Fragment>
                <Row>
                  <CardBody className="card-body-provider pe-5 ps-5 pt-5">
                    <div className="d-flex">
                      <CardTitle tag="h4">Non-Responsive Providers in Last 90 days</CardTitle>
                      <CustomToolTip
                        tooltipVisible
                        message="This list includes providers that have not responded to one or more traceback grouped with the immediate downstream that identified them. The list can be sorted by immediate downstream providers to see if there is a pattern of selecting non responsive upstream providers."
                      >
                        <i className="fa fa-info-circle ms-1 mt-1"></i>
                      </CustomToolTip>
                    </div>
                    <HomePageProviderTable
                      providerInfoType={ProviderInfoType.NrProvider}
                      homeTable
                      pageSize={10}
                    />
                  </CardBody>
                  {userTypes.Admin === user.roleType && (
                    <CardBody className="card-body-provider pe-5 ps-5 pt-5">
                      <div className="d-flex">
                        <CardTitle tag="h4">Not-Found Providers in Last 90 days</CardTitle>
                        <CustomToolTip
                          tooltipVisible
                          message="Traceback is marked Not Found by a provider because Call is too old or the Record is unavailable. This occurs on occasion in tracebacks. If it occurs frequently there may be cause by either the provider or their immediate downstream."
                        >
                          <i className="fa fa-info-circle ms-1 mt-1"></i>
                        </CustomToolTip>
                      </div>
                      <HomePageProviderTable
                        providerInfoType={ProviderInfoType.NfProvider}
                        homeTable
                        pageSize={10}
                      />
                    </CardBody>
                  )}
                  <CardBody className="card-body-provider pe-5 ps-5 pt-5">
                    <div className="d-flex">
                      <CardTitle tag="h4">Providers Added to Portal in Last 90 days</CardTitle>
                      <CustomToolTip
                        tooltipVisible
                        message="New providers are frequently identified in traceback. This list identifies those most recently added, if they are listed in the FCC's Robocall Mitigation Database, and the provider that identified them as the upstream in the call path."
                      >
                        <i className="fa fa-info-circle ms-1 mt-1"></i>
                      </CustomToolTip>
                    </div>
                    <HomePageProviderTable
                      providerInfoType={ProviderInfoType.NewProvider}
                      homeTable
                      pageSize={10}
                    />
                  </CardBody>
                </Row>
              </Fragment>
            )}
            <Row className="pt-1">
              <HomePageCustomTables />
            </Row>
            <Row className="d-flex flex-column" style={{ padding: '3rem 4rem 0rem 6rem' }}>
              <CardTitle className="first-h4 pb-3" tag="h2" style={{ color: '#47a785' }}>
                ITG Metrics
              </CardTitle>

              <Row className="pb-5">
                <Col xl={8} className="pb-3">
                  <CardTitle tag="h4">Campaign Traceback Volume </CardTitle>
                  <CampaignTracebackVolumeChart height={125} legendPosition="bottom" />
                </Col>
                <Col xl={4}>
                  <NRProvidersStats />
                </Col>
              </Row>
            </Row>

            <Row style={{ padding: '0 4rem 0 6rem' }}>
              <Col>
                <CardHeader
                  onClick={toggleFilters}
                  data-type="collapseBanner"
                  className="collapseMenu"
                >
                  Filters
                  <i
                    style={{ position: 'absolute', right: 0, marginRight: 75, marginTop: 5 }}
                    className={`fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
                  ></i>
                </CardHeader>
                <Collapse isOpen={collapseFilters}>
                  <CardBody>
                    <Row>
                      <Col className="col-2">
                        <LabelSelect
                          onChange={(option) => {
                            handleChange(option, 'labelId');
                          }}
                          isSearchable={false}
                          isClearable
                          value={filterElements.labelId}
                          placeholder="Campaign Labels"
                        />
                      </Col>
                      <Col className="col-2">
                        <Select
                          className="customselect-small"
                          classNamePrefix="customselect"
                          value={period}
                          onChange={(option) => handleChange(option, 'selectedPeriod')}
                          options={dateOptions}
                          isSearchable={false}
                        />
                      </Col>
                      {(userSourceOption || user.roleType === userTypes.Admin) && (
                        <Col className="col-5">
                          <Row className="">
                            {user.roleType === userTypes.Admin ? (
                              <div className="col-5">
                                <CustomSelect
                                  getOptions={sourceOptions}
                                  setSelectedOption={(option) => handleSourceSelect(option)}
                                  className={'customselect-small'}
                                  classNamePrefix={'customselect'}
                                  selectedOption={sourceOption}
                                  typeLabel="All"
                                />
                              </div>
                            ) : (
                              <div className="col-0">
                                <input
                                  type="checkbox"
                                  checked={source}
                                  onChange={(option) => handleChange(option, 'source')}
                                />
                              </div>
                            )}
                            {user.roleType !== userTypes.Admin && (
                              <div className="col-8 p-0">
                                <Label className="telecom-label">
                                  Show Tracebacks based on {userSourceOption?.name} source data
                                </Label>
                              </div>
                            )}
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Collapse>
              </Col>
            </Row>

            <Row style={{ padding: '3rem 4rem 0rem 6rem' }}>
              <Col className="col-xl-8">
                <CardTitle tag="h4">{`Traceback Results for "${campaignLabelName}"`} </CardTitle>
                <TracebacksCampaignLabelChart
                  chartFilters={filterElements}
                  legendPosition="right"
                  height={100}
                />
              </Col>
              <Col className="originChartCol col-xl-4 d-flex justify-content-center">
                <OriginProvidersActionsChart
                  chartFilters={filterElements}
                  legendPosition="top"
                  height={375}
                  width={375}
                />
              </Col>
            </Row>
            <Row style={{ padding: '3rem 4rem 0rem 6rem' }}>
              <Col className="col-xl-8">
                <CardTitle tag="h4">Attestation for High Volume Campaigns </CardTitle>
                <AttestationHighVolumeCampaigns
                  chartFilters={filterElements}
                  legendPosition="top"
                  height={100}
                />
              </Col>
              <Col className="originChartCol col-xl-4 d-flex justify-content-center">
                <div className="text-center">
                  <CardTitle tag="h4">STIR/SHAKEN US Origin</CardTitle>
                  <OriginSignedStatsChart
                    chartFilters={filterElements}
                    legendPosition="top"
                    height={340}
                    width={340}
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex mapChart" style={{ padding: '3rem 4rem 3rem 6rem' }}>
              <div className="mapCol col-6">
                <CardTitle tag="h4">Origin Provider Locations</CardTitle>
                <MapChart chosenKey="world" chartFilters={filterElements} />
              </div>
              <div className="mapCol col-6">
                <CardTitle tag="h4">Caller Locations</CardTitle>
                <MapChart chosenKey="world" chartFilters={filterElements} hops />
              </div>
            </div>
          </div>

          <div
            className={`d-flex justify-content-${
              user.roleType === userTypes.Admin || user.roleType === userTypes.GovUser
                ? 'between'
                : 'end'
            }`}
            style={{ padding: '0 4rem 2rem 6rem' }}
          >
            {(user.roleType === userTypes.Admin || user.roleType === userTypes.GovUser) && (
              <Button
                className="downloadCsvStyle pe-2 ps-2"
                onClick={() => {
                  downloadReportApi(ReportType.SixtyDaysReport, ReportFormat.Excel);
                }}
              >
                <i className="fa fa-download pe-1" />
                {`Download 60Days Report`}
              </Button>
            )}
            <DownloadPdfFromView ref={printPDF} />
          </div>
        </Fragment>
      </Card>
      <div className="d-flex justify-content-center" style={{ padding: '2rem 3rem' }}>
        <Legend />
      </div>
      <ZendeskWebWidget />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

const mapActionsToProps = {
  downloadReportApi
};

export default connect(mapStateToProps, mapActionsToProps)(HomePage);
