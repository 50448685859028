import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, FormGroup } from 'reactstrap';
import InputFormGroup from '../components/inputFormGroup';
import { validEmailRegex } from '../lib/regex';
import { generateResetPasswordEmail } from '../redux/auth/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  generateResetPasswordEmail: Function;
  isLoading: boolean;
}

const ResetPasswordPublic: React.FC<IProps> = ({ generateResetPasswordEmail, isLoading }) => {
  const [searchParams, _] = useSearchParams();
  const errorMessage = useMemo(() => {
    switch (searchParams.get('errorMessage')) {
      case 'inactivity':
        return 'You have been logged out due to inactivity.';
      case 'invalid':
        return 'Invalid or Expired Link';
      default:
        break;
    }
    return '';
  }, [searchParams]);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (email && !validEmailRegex.test(email)) setError('Please provide a valid email address.');
    else setError('');
  }, [email]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && !error) generateResetPasswordEmail(email);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.currentTarget.value);
  };

  return (
    <Card className="card-password-reset">
      <CardBody>
        <div className="d-flex justify-content-center">
          <Col xs="12" sm="6" lg="3">
            <Form className="card-auth-form" onSubmit={onSubmit}>
              {errorMessage && (
                <p className="input-error text-center">
                  <span className="text-fadein">
                    The setup link has expired. To generate a new link please enter the email
                    address which was invited.
                  </span>
                </p>
              )}
              <FormGroup style={{ textAlign: 'center', marginBottom: '15px' }}>
                <p className="reset-password-text">Reset your password</p>
              </FormGroup>
              <FormGroup style={{ marginBottom: '20px' }}>
                <p className="reset-password-description">
                  Please enter the email address used to create your account. If the email address
                  you provide is found in our system, you will be sent a password reset link.
                </p>

                <p className="reset-password-description mb-0">
                  This link will expire in 30 minutes.
                </p>
              </FormGroup>
              <InputFormGroup
                inputName="username"
                inputId="username"
                inputValue={email}
                inputClassName="login-control"
                inputOnChange={handleChange}
                inputPlaceholder="Email"
                inputAutoComplete="off"
                inputErrorStyle={{ marginTop: '4px', marginBottom: '12px' }}
                errorMessage={error}
              ></InputFormGroup>
              <FormGroup style={{ textAlign: 'center' }}>
                <Button type="submit" className="btn-login" disabled={isLoading}>
                  Request Reset Link
                </Button>
              </FormGroup>
              <FormGroup style={{ textAlign: 'center', marginBottom: '60px' }}>
                <a className="btn-link forgot-password" href="/login">
                  Return to Login
                </a>
              </FormGroup>
              <FormGroup style={{ textAlign: 'center' }}>
                <a className="btn-link forgot-password" href="/support">
                  Contact Support
                </a>
              </FormGroup>
            </Form>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { isLoading: sm.auth.loading || false };
};

const mapActionsToProps = { generateResetPasswordEmail };

export default connect(mapStateToProps, mapActionsToProps)(ResetPasswordPublic);
