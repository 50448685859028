import { Provider } from './actionEnum';

export const getProvidersList = (response: any) => ({
  type: Provider.GET_PROVIDER_LIST,
  payload: response
});

export const getProviders = (response: any) => ({
  type: Provider.GET_PROVIDERS,
  payload: response
});

export const getProvider = (response: any) => ({
  type: Provider.GET_PROVIDER,
  payload: response
});

export const getProvidersCountriesAction = (response: any) => ({
  type: Provider.GET_PROVIDERS_COUNTRIES,
  payload: response
});

export const addProvider = (response: any) => ({
  type: Provider.ADD_PROVIDER,
  payload: response
});

export const editProvider = (response: any) => ({
  type: Provider.EDIT_PROVIDER,
  payload: response
});

export const updatePendingProvider = (response: any) => ({
  type: Provider.UPDATE_PENDING_PROVIDER,
  payload: response
});

export const getAttachments = (response: any) => ({
  type: Provider.GET_ATTACHMENTS,
  payload: response
});

export const addAttachments = (response: any) => ({
  type: Provider.ADD_ATTACHMENTS,
  payload: response
});

export const getProviderContacts = (response: any) => ({
  type: Provider.GET_PROVIDER_CONTACTS,
  payload: response
});

export const setProviderContacts = (response: any) => ({
  type: Provider.SET_PROVIDER_CONTACTS,
  payload: response
});

export const getProviderTypes = (response: any) => ({
  type: Provider.GET_PROVIDER_TYPES,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Provider.SET_PROVIDER_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Provider.SET_PROVIDER_ERROR_MESSAGE,
  payload: message
});

export const getProviderSummaryLow = (response: any) => ({
  type: Provider.GET_PROVIDER_SUMMARY_LOW,
  payload: response
});
export const getProviderSummaryHigh = (response: any) => ({
  type: Provider.GET_PROVIDER_SUMMARY_HIGH,
  payload: response
});
export const getProviderSummaryInternational = (response: any) => ({
  type: Provider.GET_PROVIDER_SUMMARY_INTERNATIONAL,
  payload: response
});
export const getProviderUpstreamSummary = (response: any) => ({
  type: Provider.GET_PROVIDER_UPSTREAMSUMMARY,
  payload: response
});

export const getProviderStats = (response: any) => ({
  type: Provider.GET_PROVIDER_STATS,
  payload: response
});

export const getAllProvidersStats = (response: any) => ({
  type: Provider.GET_ALL_PROVIDERS_STATS,
  payload: response
});

export const getProviderActionsStatsAction = (response: any) => ({
  type: Provider.GET_PROVIDER_ACTIONS_STATS,
  payload: response
});

export const getAverageDailyTrafficStatsAction = (response: any) => ({
  type: Provider.GET_AVERAGE_DAILY_TRAFFIC_STATS,
  payload: response
});

export const getProviderNames = (response: any) => ({
  type: Provider.GET_PROVIDER_NAMES,
  payload: response
});

export const getProvidersNRStats = (response: any) => ({
  type: Provider.GET_PROVIDERS_NR_STATS,
  payload: response
});
export const getProvidersORGStats = (response: any) => ({
  type: Provider.GET_PROVIDERS_ORG_STATS,
  payload: response
});

export const getProviderIsNonResponsiveAction = (response: any) => ({
  type: Provider.GET_PROVIDER_NR,
  payload: response
});

export const getNewProvidersInfoAction = (response: any) => ({
  type: Provider.GET_NEW_PROVIDERS_INFO,
  payload: response
});

export const getNrProvidersInfoAction = (response: any) => ({
  type: Provider.GET_NR_PROVIDERS_INFO,
  payload: response
});

export const getNfProvidersInfoAction = (response: any) => ({
  type: Provider.GET_NF_PROVIDERS_INFO,
  payload: response
});

export const getProvidersIpInfoAction = (response: any) => ({
  type: Provider.GET_PROVIDERS_IP_INFO,
  payload: response
});

export const getProviderStatesAction = (providerStates: string[]) => ({
  type: Provider.GET_PROVIDER_ACTIONS_STATS,
  payload: providerStates
});

export const getProviderStirShakenStatsAction = (response: any) => ({
  type: Provider.GET_PROVIDER_STIRSHAKEN_STATS,
  payload: response
});

export const getProviderComments = (response: any) => ({
  type: Provider.GET_PROVIDER_COMMENTS,
  payload: response
});
