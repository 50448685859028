import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap';
import InputFormGroup from '../components/inputFormGroup';
import { validEmailRegex } from '../lib/regex';
import { rememberPage, signIn } from '../redux/auth/thunks';
import { stateMappings } from '../redux/stateMappings';
interface IProps {
  signIn: Function;
  rememberPage: Function;
  isAuthenticated: boolean;
  isError: boolean;
  authError: string;
  lastPageUrl: string;
  lastUserId: number;
}
interface UserCredentials {
  username: string;
  password: string;
}

const Login: React.FC<IProps> = ({
  signIn,
  isAuthenticated,
  isError,
  authError,
  lastPageUrl,
  rememberPage
}) => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<UserCredentials>({
    username: localStorage.getItem('USTELECOMEUSERNAME') || '',
    password: ''
  });
  const [isRemember, setIsRemember] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [searchParams, _] = useSearchParams();
  const errorMessage = useMemo(() => {
    switch (searchParams.get('errorMessage')) {
      case 'inactivity':
        return 'You have been logged out due to inactivity.';
      case 'invalid':
        return 'Invalid or Expired Link';
      default:
        break;
    }
    return '';
  }, [searchParams]);

  useEffect(() => {
    if (authError === 'AccountLocked') setAttempts(6);
  }, [authError]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const page = lastPageUrl || '/';
    rememberPage(''); // forget this page after a succesful login
    navigate(page);
  }, [isAuthenticated]);

  useEffect(() => {
    if (attempts > 5) {
      const timer = setTimeout(
        () => {
          setAttempts(0);
        },
        15 * 60 * 1e3
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [attempts]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.currentTarget;
    let newCredentials = { ...credentials };

    switch (name) {
      case 'username':
        newCredentials.username = value;
        setAttempts(0);
        setIsValidEmail(validEmailRegex.test(value));
        break;
      case 'password':
        newCredentials.password = value;
        setIsValidPassword(!!value);
        break;
    }

    setCredentials(newCredentials);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { username, password } = credentials;

    setIsValidEmail(validEmailRegex.test(username));
    setIsValidPassword(password !== '');

    setAttempts(attempts + 1);

    signIn({ username, password, isRemember });
  };

  const handleCheck = () => {
    setIsRemember((isRemember) => !isRemember);
  };

  return (
    <Card className="card-sign">
      <CardBody>
        <div className="container-fluid">
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="8" lg="5">
              <Form className="card-auth-form" onSubmit={onSubmit}>
                <FormGroup className="login-form-group">
                  <p className="text-center text-welcome">WELCOME</p>
                  <p className="text-center sub-text-welcome">
                    Log in to the <b>Industry Traceback Group's Secure Traceback Portal</b>
                  </p>
                </FormGroup>
                <InputFormGroup
                  containerClassName="login-form-group"
                  inputName="username"
                  inputClassName="login-control"
                  inputId="username"
                  inputValue={credentials.username}
                  inputOnChange={handleChange}
                  inputPlaceholder="Email"
                  inputAutoComplete="off"
                  isAppend={true}
                  inputIcon="user"
                  errorMessage={isValidEmail ? '' : 'Please provide a valid email address.'}
                ></InputFormGroup>
                <InputFormGroup
                  containerClassName="login-form-group mt-3"
                  isPassword
                  inputName="password"
                  inputClassName="login-control"
                  inputId="password"
                  inputValue={credentials.password}
                  inputOnChange={handleChange}
                  inputPlaceholder="Password"
                  inputAutoComplete="off"
                  isAppend={true}
                  inputIcon="lock"
                  errorMessage={
                    (isError || !isValidPassword) && (
                      <Fragment>
                        {isError &&
                          attempts !== 0 &&
                          (attempts > 2 && attempts <= 5 ? (
                            <Fragment>
                              It appears you may have forgotten your password. Would you like to{' '}
                              <a href="/reset-password" style={{ color: 'red' }}>
                                reset
                              </a>{' '}
                              it?
                            </Fragment>
                          ) : (
                            isError &&
                            (attempts > 5
                              ? 'You attempted to log in too many times, try again in 15 minutes'
                              : 'Incorrect login credentials. ')
                          ))}
                        {!isValidPassword && <p>Please provide a valid password.</p>}
                      </Fragment>
                    )
                  }
                ></InputFormGroup>

                <FormGroup className="login-form-group mt-3" style={{ marginBottom: '20px' }}>
                  <Row className="checkbox-remember">
                    <label className="form-label remember-me">
                      Remember me
                      <input type="checkbox" onChange={handleCheck} defaultChecked={isRemember} />
                      <span className="checkmark"></span>
                    </label>
                  </Row>
                </FormGroup>
                {attempts <= 5 ? (
                  <FormGroup className="login-form-group" style={{ textAlign: 'center' }}>
                    <Button type="submit" className="btn-login">
                      Log In
                    </Button>
                  </FormGroup>
                ) : (
                  <FormGroup className="login-form-group" style={{ textAlign: 'center' }}>
                    <Button type="button" className="btn-login">
                      Log In
                    </Button>
                  </FormGroup>
                )}
                {errorMessage && (
                  <p className="input-error text-center">
                    <span className="text-fadein">{errorMessage}</span>
                  </p>
                )}
                <FormGroup className="login-form-group" style={{ textAlign: 'center' }}>
                  <a className="btn-link forgot-password" href="/reset-password">
                    Forgot password
                  </a>
                </FormGroup>
              </Form>
            </Col>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    isError: !!sm.error.auth,
    authError: sm.error.auth,
    lastPageUrl: sm.lastPageUrl,
    lastUserId: sm.lastUserId
  };
};

const mapActionsToProps = { signIn, rememberPage };

export default connect(mapStateToProps, mapActionsToProps)(Login);
